import { lazy, LazyExoticComponent } from "react";
import { ACCOUNT_URLS, memberProfile, NO_MENU_URLS, URLS } from "./settings";

const Home = lazy(() => import("./screens/Home"));
const NotFound = lazy(() => import("./screens/NotFound"));
const Login = lazy(() => import("./screens/Login"));
const Players = lazy(() => import("./screens/player/Players"));
const Logout = lazy(() => import("./screens/Logout"));
const NewPlayer = lazy(() => import("./screens/player/NewPlayer"));
const NewArbiter = lazy(() => import("./screens/arbiter/NewArbiter"));
const Arbiters = lazy(() => import("./screens/arbiter/Arbiters"));
const Services = lazy(() => import("./screens/Services"));
const Clubs = lazy(() => import("./screens/club/Clubs"));
const Club = lazy(() => import("./screens/club/Club"));
const NewClub = lazy(() => import("./screens/club/NewClub"));
const Trainers = lazy(() => import("./screens/trainer/Trainers"));
const NewTrainer = lazy(() => import("./screens/trainer/NewTrainer"));
const Application = lazy(() => import("./screens/Application"));
const NewTournament = lazy(() => import("./screens/tournament/NewTournament"));
const Tournaments = lazy(() => import("./screens/tournament/Tournaments"));
const Tournament = lazy(() => import("./screens/tournament/Tournament"));
const Panel = lazy(() => import("./screens/Panel"));
const UserManagement = lazy(() => import("./screens/UserManagement"));
const Log = lazy(() => import("./screens/Log"));
const PlayerAppForms = lazy(() => import("./screens/player/PlayerAppForms"));
const ConfirmationTournament = lazy(() => import("./screens/tournament/ConfirmationTournament"));
const ChangePassword = lazy(() => import("./screens/ChangePassword"));
const Backups = lazy(() => import("./screens/Backups"));
const FinancialReports = lazy(() => import("./screens/financialReport/FinancialReports"));
const FinancialReport = lazy(() => import("./screens/financialReport/FinancialReport"));
const NewFinancialReport = lazy(() => import("./screens/financialReport/NewFinancialReport"));
const NewArbiterAssembly = lazy(() => import("./screens/arbiter/NewArbiterAssembly"));
const ArbiterAssemblies = lazy(() => import("./screens/arbiter/ArbiterAssemblies"));
const ArbiterAssembly = lazy(() => import("./screens/arbiter/ArbiterAssembly"));
const ConfirmLicenses = lazy(() => import("./screens/ConfirmLicenses"));
const PlayerProfile = lazy(() => import("./screens/player/PlayerProfile"));
const ConfirmationTitle = lazy(() => import("./screens/player/ConfirmationTitle"));
const ConfirmChangeClub = lazy(() => import("./screens/club/ConfirmChangeClub"));

interface SiteMapItem {
  path: string;
  element: LazyExoticComponent<() => React.JSX.Element> | (() => React.JSX.Element);
}

const SiteMap: SiteMapItem[] = [
  { path: URLS.home.url, element: Home },
  { path: URLS.players.url, element: Players },
  { path: memberProfile + "/:profileType/:playerId/", element: PlayerProfile },
  { path: ACCOUNT_URLS.login.url, element: Login },
  { path: ACCOUNT_URLS.logout.url, element: Logout },
  { path: NO_MENU_URLS.player.newPlayer, element: NewPlayer },
  { path: NO_MENU_URLS.arbiter.newArbiter, element: NewArbiter },
  { path: NO_MENU_URLS.trainer.newTrainer, element: NewTrainer },
  { path: NO_MENU_URLS.club.newClub, element: NewClub },
  { path: URLS.arbiters.url, element: Arbiters },
  { path: URLS.services.url, element: Services },
  { path: URLS.clubs.url, element: Clubs },
  { path: NO_MENU_URLS.club.club + ":clubId", element: Club },
  { path: URLS.trainers.url, element: Trainers },
  { path: URLS.application.url, element: Application },
  { path: NO_MENU_URLS.tournament.newTournament, element: NewTournament },
  { path: URLS.tournaments.url, element: Tournaments },
  {
    path: NO_MENU_URLS.tournament.tournament + ":tournamentId",
    element: Tournament,
  },
  { path: ACCOUNT_URLS.panel.url, element: Panel },
  { path: NO_MENU_URLS.userManagement, element: UserManagement },
  { path: NO_MENU_URLS.log, element: Log },
  { path: NO_MENU_URLS.confirm.players, element: PlayerAppForms },
  { path: NO_MENU_URLS.confirm.tournaments, element: ConfirmationTournament },
  { path: NO_MENU_URLS.changePassword, element: ChangePassword },
  { path: NO_MENU_URLS.backups, element: Backups },
  {
    path: NO_MENU_URLS.financialReport.financialReports,
    element: FinancialReports,
  },
  {
    path: NO_MENU_URLS.financialReport.newFinancialReport,
    element: NewFinancialReport,
  },
  {
    path: NO_MENU_URLS.financialReport.financialReport + ":reportId",
    element: FinancialReport,
  },
  {
    path: NO_MENU_URLS.arbiter.newAssembly,
    element: NewArbiterAssembly,
  },
  { path: URLS.ArbiterAssemblies.url, element: ArbiterAssemblies },
  {
    path: NO_MENU_URLS.arbiter.assembly + ":assemblyId",
    element: ArbiterAssembly,
  },
  {
    path: NO_MENU_URLS.confirmLicenses,
    element: ConfirmLicenses,
  },
  { path: NO_MENU_URLS.confirmTitles, element: ConfirmationTitle },
  { path: NO_MENU_URLS.club.confirmChange, element: ConfirmChangeClub },
  { path: "*", element: NotFound },
];
export default SiteMap;
