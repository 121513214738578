import React, { useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/Menu.scss";
import { ACCOUNT_URLS } from "../../settings";
import GlobalContext from "../../GlobalContext";
import { URLObject } from "../../types/URLObject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconLookup, IconName, IconPrefix } from "@fortawesome/free-solid-svg-icons";
import { Tab, TabNavigation } from "evergreen-ui";

/**
 * Props for the Menu component.
 *
 * @interface
 */
interface MenuProps {
  /** Object containing navigation links. */
  links: Record<string, URLObject>;
}

/**
 * Menu component for rendering navigation links.
 *
 * The menu displays different links based on the user's authentication state.
 * If the user is logged in, it shows additional links for user-related actions
 * and session expiration information.
 *
 * @component
 * @param {MenuProps} props - The props for the component.
 * @returns {React.JSX.Element} The rendered Menu component.
 */
const Menu: React.FC<MenuProps> = ({ links }: MenuProps): React.JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useContext(GlobalContext)!;
  const expirationDate = state.expiration ? new Date(parseInt(state.expiration)) : null;

  const tmp: Record<string, URLObject> = useMemo(
    () => ({
      ...links,
      ...(state.token && { panel: ACCOUNT_URLS.panel }),
      log: state.token ? ACCOUNT_URLS.logout : ACCOUNT_URLS.login,
    }),
    [state.token, links],
  );

  const menuContent = (
    <>
      <TabNavigation>
        {Object.keys(tmp).map((key) => (
          <Tab
            href={tmp[key].url}
            is="a"
            isSelected={new RegExp(`^${location.pathname}\\d*$`).test(tmp[key].url)}
            key={key}
            onClick={(e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
              e.preventDefault();
              navigate(tmp[key].url);
            }}
          >
            {tmp[key].icon ? (
              <FontAwesomeIcon
                icon={tmp[key].icon as IconName | [IconPrefix, IconName] | IconLookup}
              />
            ) : (
              tmp[key].icons !== undefined && (
                <>
                  {tmp[key].icons!.map((item) => (
                    <FontAwesomeIcon
                      icon={item as IconName | [IconPrefix, IconName] | IconLookup}
                    />
                  ))}
                </>
              )
            )}{" "}
            {tmp[key].name}
          </Tab>
        ))}
      </TabNavigation>
      {state.token && state.user && (
        <div>
          Zalogowany jako {state.user}
          {expirationDate && (
            <>
              , sesja wygasa {("0" + expirationDate.getDate()).slice(-2)}.
              {("0" + (expirationDate.getMonth() + 1)).slice(-2)}.{expirationDate.getFullYear()}{" "}
              {("0" + expirationDate.getHours()).slice(-2)}:
              {("0" + expirationDate.getMinutes()).slice(-2)}:
              {("0" + expirationDate.getSeconds()).slice(-2)}
            </>
          )}
        </div>
      )}
    </>
  );

  return (
    <nav>
      <details className="mobile">
        <summary>Menu</summary>
        <div>{menuContent}</div>
      </details>
      <div className="desktop">{menuContent}</div>
    </nav>
  );
};

export default Menu;
