import React, { useEffect, useState, useRef, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import "./styles/App.scss";
import { ACCOUNT_URLS, URLS } from "./settings";
import GlobalContext from "./GlobalContext";
import Menu from "./components/app/Menu";
import Footer from "./components/app/Footer";
import ErrorBoundary from "./components/app/ErrorBoundary";
import useAlert from "./useAlert";
import URLLogger from "./URLLogger";
import { AppState } from "./types/AppState";
import SiteMap from "./SiteMap";
import { Spinner } from "evergreen-ui";

const checkExpiration = ({
  token,
  ref,
}: {
  token: string | undefined;
  ref: NodeJS.Timeout | null;
}) => {
  if (token && Cookies.get("token") !== "undefined") {
    const expirationDate = new Date(parseInt(Cookies.get("expiration") || "0"));
    if (new Date() > expirationDate) {
      window.location.href = ACCOUNT_URLS.logout.url;
    } else {
      ref = setTimeout(
        () => checkExpiration({ token, ref }),
        expirationDate.getTime() - new Date().getTime(),
      );
    }
  } else {
    window.location.reload();
  }
};

function App() {
  const [showConfirm, AlertComponent] = useAlert();
  const [state, setState] = useState<AppState>({
    token: Cookies.get("token") === "undefined" ? undefined : Cookies.get("token"),
    expiration: Cookies.get("expiration") === "undefined" ? undefined : Cookies.get("expiration"),
    user: Cookies.get("user") === "undefined" ? undefined : Cookies.get("user"),
    showConfirm,
  });

  if (state.token) {
    const token = Cookies.get("token") || "";
    const user = Cookies.get("user") || "";
    Cookies.set("token", token, {
      expires: 1 / 24 / 4,
      secure: true,
      sameSite: "Lax",
    });
    Cookies.set("user", user, {
      expires: 1 / 24 / 4,
      secure: true,
      sameSite: "Lax",
    });
    Cookies.set("expiration", new Date().setMinutes(new Date().getMinutes() + 15).toString(), {
      expires: 1 / 24 / 4,
      secure: true,
      sameSite: "Lax",
    });
  }

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (state.token) {
      const expirationTime = parseInt(state.expiration || "0");
      const timeoutDuration = expirationTime - new Date().getTime();

      timeoutRef.current = setTimeout(
        () => checkExpiration({ token: state.token, ref: timeoutRef.current }),
        timeoutDuration,
      );
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [state.token, state.expiration]);

  return (
    <GlobalContext.Provider value={{ state, setState }}>
      <ErrorBoundary>
        <div className="App light">
          <Router>
            <Menu links={URLS} />
            {AlertComponent}
            <div id="content">
              <Suspense fallback={<Spinner />}>
                <ErrorBoundary>
                  <Routes>
                    {SiteMap.map((item) => (
                      <Route path={item.path} element={<item.element />} />
                    ))}
                  </Routes>
                  <URLLogger setState={setState} />
                </ErrorBoundary>
              </Suspense>
            </div>
          </Router>
          <Footer />
        </div>
      </ErrorBoundary>
    </GlobalContext.Provider>
  );
}

export default App;
