import { useState, createElement } from "react";
import Alert from "./components/app/Alert";
import { DefaultThemeIntent } from "evergreen-ui";

type ResolvePromise = (value: boolean | undefined) => void;

const useAlert = (): [
  (msg: string, intent: DefaultThemeIntent) => Promise<boolean>,
  React.JSX.Element | null,
  string,
] => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [resolvePromise, setResolvePromise] = useState<ResolvePromise | null>(null);
  const [intent, setIntent] = useState<DefaultThemeIntent>("info");

  const showConfirm = (msg: string, intent: DefaultThemeIntent): Promise<boolean> => {
    setMessage(msg);
    setIntent(intent);
    setIsVisible(true);
    return new Promise<boolean>((resolve) => {
      setResolvePromise(() => resolve);
    });
  };

  const handleConfirm = (value: boolean) => {
    setIsVisible(false);
    setMessage("");
    setIntent("info");
    if (resolvePromise) {
      resolvePromise(value);
    }
  };

  const handleCancel = () => {
    setIsVisible(false);
    setMessage("");
    setIntent("info");
    if (resolvePromise) {
      resolvePromise(false);
    }
  };

  const AlertComponent = isVisible
    ? createElement(Alert, {
        message: message,
        onConfirm: () => handleConfirm(true),
        onCancel: handleCancel,
        intent,
      })
    : null;

  return [showConfirm, AlertComponent, message];
};

export default useAlert;
